import Pagination from '@mui/material/Pagination';
import Header from 'components/layouts/Header';
import Menu from 'components/layouts/Menu';
import config from 'config';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useAppDispatch } from 'reducer';
import { errorAlert } from 'reducer/slices/alertSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import { showPopup } from 'reducer/slices/popupSlice';
import gather from 'tools/gather';
import ChangeEmail from './ChangeEmail';
import Check from './Check';

const Transactions = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState([]);
  const [searchCoin, setSearchCoin] = useState('');
  const [searchLimit, setSearchLimit] = useState('');
  const [searchSide, setSearchSide] = useState('');
  const [searchType, setSearchType] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [searchFromDate, setSearchFromDate] = useState('');
  const [searchToDate, setSearchToDate] = useState('');
  const [searchSearchAll, setSearchSearchAll] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [clear, setClear] = useState(true);
  const [coins, setCoins] = useState<any>([]);

  const inquiry = async (id: string) => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/transaction/${id}/inquiry`, true).get();

    if (result.code === 200) {
      dispatch(showPopup(<Check data={result.data} />));
    } else {
      dispatch(errorAlert(result.message ?? 'Not connected to server'));
    }

    dispatch(hideLoading());
  };

  const dataExport: any = [['ID', 'Amount', 'Fee', 'Coin', 'Side', 'Type', 'Status', 'Date']];
  data.forEach((e: any) => {
    const ver: any = [];
    Object.entries(e.data! || []).forEach((x: any, k) => {
      if (x[1]) {
        ver.push(x[0] + ':' + x[1]);
      }
    });

    dataExport.push([e.id, e.amount, e.fee, e.coin, e.side, e.type, e.status, moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss')].concat(ver));
  });

  const listAllCoin = () => {
    const rows: React.ReactElement[] = [];

    coins
      ?.sort((a: any, b: any) => a.symbol.localeCompare(b.symbol))
      .forEach((e: any, i: number) => {
        rows.push(
          <option key={i} value={e.symbol}>
            {e.symbol}
          </option>,
        );
      });

    return rows;
  };

  const list = () => {
    const rows: React.ReactElement[] = [];

    data.forEach((e: any, i: number) => {
      const ver: any = [];
      Object.entries(e.data! || []).forEach((x: any, k) => {
        if (x[1]) {
          ver.push(
            <div key={k} style={{ fontSize: 12 }}>
              <span style={{ color: '#999', fontSize: 12 }}>{x[0]}</span>: {x[1]}
            </div>,
          );
        }
      });

      rows.push(
        <tr key={i}>
          <td>{e.id}</td>
          <td>
            {ver || '-'}{' '}
            {e?.data?.email && e.side === 'send' && e.type === 'voucher' && e.status === 'confirm' && (
              <>
                <span className='changeEmail' onClick={() => dispatch(showPopup(<ChangeEmail />))}>
                  Change Email
                </span>
                <span className='changeEmail sendEmail'>Send Email</span>
              </>
            )}
            {e.side === 'send' && e.type === 'voucher' && e.status === 'confirm' && (
              <span className='changeEmail sendEmail' onClick={() => inquiry(e.id)}>
                STATUS
              </span>
            )}
          </td>
          <td>{e.amount}</td>
          <td>{e.fee}</td>
          <td>{e.coin}</td>
          <td>{e.side}</td>
          <td>{e.type}</td>
          <td className='setting'>{e.status}</td>
          <td>{moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
        </tr>,
      );
    });

    return rows;
  };

  const getCoin = async () => {
    const result = await gather(`${config.apiUrl}/v1/coin`, true).get();
    if (result.code === 200) {
      setCoins(result.data);
    }
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(
      `${config.apiUrl}/v1/transaction?page=${currentPage}&coin=${coins.find((e: any) => e.symbol === searchCoin)?._id ||
        ''}&searchAll=${searchSearchAll}&side=${searchSide}&type=${searchType}&status=${searchStatus}&fromDate=${Number(moment(searchFromDate).unix() * 1000) ||
        ''}&toDate=${Number(moment(searchToDate).unix() * 1000) || ''}&limit=${searchLimit || 50}`,
      true,
    ).get();
    if (result.code === 200) {
      setData(result.data);
      // setCurrentPage(result.data.pages.current);
      // setCountPage(result.data.pages.count);
    } else {
      dispatch(errorAlert(result.message));
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    getCoin();
    init();
  }, [currentPage, clear]);

  return (
    <>
      <Menu />
      <div className='container'>
        <Header />
        <div className='searchPro'>
          <input value={searchSearchAll} placeholder='Hash, Code, Ref, Sender ...' onChange={(e) => setSearchSearchAll(e.target.value)} />
          <select value={searchCoin} onChange={(e) => setSearchCoin(e.target.value)}>
            <option>Coin</option>
            {listAllCoin()}
          </select>
          <select value={searchSide} onChange={(e) => setSearchSide(e.target.value)}>
            <option>Side</option>
            <option value='send'>Send</option>
            <option value='receive'>Receive</option>
          </select>
          <select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
            <option>Type</option>
            <option value='direct'>Direct</option>
            <option value='voucher'>Voucher</option>
            <option value='swap'>Swap</option>
            <option value='fast'>Fast</option>
            <option value='energy'>Energy</option>
          </select>
          <select value={searchStatus} onChange={(e) => setSearchStatus(e.target.value)}>
            <option>Status</option>
            <option value='confirm'>Confirm</option>
            <option value='reject'>Reject</option>
            <option value='cancel'>Cancel</option>
            <option value='pending'>Pending</option>
            <option value='processing'>Processing</option>
          </select>
          <input type='date' value={searchFromDate} onChange={(e) => setSearchFromDate(e.target.value)} />
          <input type='date' value={searchToDate} onChange={(e) => setSearchToDate(e.target.value)} />

          <select value={searchLimit} onChange={(e) => setSearchLimit(e.target.value)}>
            <option>Limit</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
            <option value='200'>200</option>
            <option value='300'>300</option>
            <option value='400'>400</option>
          </select>
          <div
            onClick={() => {
              setCurrentPage(1);
              setClear(!clear);
            }}
          >
            SEARCH
          </div>
          <div
            onClick={() => {
              setSearchLimit('');
              setSearchCoin('');
              setSearchFromDate('');
              setSearchToDate('');
              setSearchSide('');
              setSearchStatus('');
              setSearchType('');
              setSearchSearchAll('');
              setCurrentPage(1);
              setClear(!clear);
              setTimeout(() => {
                // init();
              }, 500);
            }}
          >
            CLEAR
          </div>
          <CSVLink data={dataExport} filename={'uwallet-export.csv'}>
            EXPORT
          </CSVLink>
        </div>
        <table className='tableData'>
          <tbody>
            <tr>
              <td>ID</td>
              <td>Data</td>
              <td>Amount</td>
              <td>Fee</td>
              <td>Coin</td>
              <td>Side</td>
              <td>Type</td>
              <td>Status</td>
              <td>Date</td>
            </tr>
            {list()}
          </tbody>
        </table>
        <Pagination count={countPage} color='primary' onChange={(_e, v) => setCurrentPage(v)} />
      </div>
    </>
  );
};

export default Transactions;
