import config from 'config';
import { useState } from 'react';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import { hidePopup } from 'reducer/slices/popupSlice';
import gather from 'tools/gather';

const ChangeEmail = () => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');

  const submit = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1`, true).put({
      email: email,
    });

    if (result.code === 200) {
      dispatch(hidePopup());
      dispatch(successAlert('Done!'));
    } else {
      dispatch(errorAlert(result.message ?? 'Not connected to server'));
    }

    dispatch(hideLoading());
  };

  return (
    <div className='login'>
      <div className='inputLogin'>
        <span>New Email</span>
        <input type='text' onChange={(e) => setEmail(e.target.value)} />
      </div>

      <div className='submit' onClick={() => submit()}>
        Submit
      </div>
    </div>
  );
};

export default ChangeEmail;
