import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from 'config';
import gather from 'tools/gather';

export const getProfile = createAsyncThunk('getProfile', async () => {
  return await gather(`${config.apiUrl}/v1/user`, true).get();
});

export const slice = createSlice({
  name: 'user',
  initialState: {
    profile: {
      email: '',
      nickname: '',
      publicId: '',
    },
    balance: [] as any,
  },
  reducers: {
    updateWallet: (state, action) => {
      const index = state.balance.findIndex((e: any) => e.coin === action.payload.coin);
      state.balance[index] = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.profile = action.payload.data as any;
    });
  },
});

export const { updateWallet } = slice.actions;
export default slice.reducer;
