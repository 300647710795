import { FaChartArea, FaDatabase, FaEdit } from 'react-icons/fa';

const config = {
  apiUrl: process.env.REACT_APP_API_URL!,
  menu: [
    {
      title: 'Dashboard',
      url: '/',
      icon: <FaChartArea />,
    },
    {
      title: 'Transactions',
      url: '/transactions',
      icon: <FaDatabase />,
    },
    {
      title: 'Sub Admin',
      url: '/sub',
      icon: <FaEdit />,
    },
  ],
};

export default config;
