import config from 'config';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';

const Login = ({ setIsLogin }: any) => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [isForm, setIsForm] = useState(true);
  const [isSub, setIsSub] = useState(false);

  const confirmCode = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/auth/signin/confirm`).post({
      email: email,
      code: code,
    });

    if (result.code === 200) {
      Cookies.set('refreshTokenPanel', result.data.refreshToken, { expires: 365 });
      Cookies.set('accessTokenPanel', result.data.accessToken, { expires: 365 });
      Cookies.set('isSub', 'no');

      dispatch(successAlert('Login successful'));

      window.location.reload();
    } else {
      dispatch(errorAlert(result.message ?? 'Not connected to server'));
      dispatch(hideLoading());
    }
  };

  const singin = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/auth/signin`).post({
      email: email,
      password: password,
    });

    if (result.code === 200) {
      if (result.data.accessToken) {
        Cookies.set('refreshTokenPanel', result.data.refreshToken);
        Cookies.set('accessTokenPanel', result.data.accessToken);
        Cookies.set('isSub', 'no');

        dispatch(successAlert('Login successful'));

        window.location.reload();
      } else {
        setIsForm(false);
      }
    } else {
      dispatch(errorAlert(result.message ?? 'Not connected to server'));
    }

    dispatch(hideLoading());
  };

  const singinSub = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/sub/login`).post({
      username: email,
      password: password,
    });

    if (result.code === 200) {
      Cookies.set('refreshTokenPanel', result.data.refreshToken);
      Cookies.set('accessTokenPanel', result.data.accessToken);
      Cookies.set('isSub', 'yes');

      dispatch(successAlert('Login successful'));

      window.location.reload();
    } else {
      dispatch(errorAlert(result.message ?? 'Not connected to server'));
    }

    dispatch(hideLoading());
  };

  return isForm ? (
    <div className='login'>
      <h3>Enter Your Email And Password</h3>
      <div className='inputLogin'>
        <span>Email</span>
        <input type='text' onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div className='inputLogin'>
        <span>Password</span>
        <input type='password' onChange={(e) => setPassword(e.target.value)} />
      </div>
      <div className='inputSub'>
        <label>
          <input type='checkbox' onChange={() => setIsSub(!isSub)} value={'yes'} />
          <b>I'm sub admin</b>
        </label>
      </div>
      <div className='submit' onClick={() => (isSub ? singinSub() : singin())}>
        Login
      </div>
    </div>
  ) : (
    <div className='login'>
      <h3>Enter Verify Code</h3>
      <div className='inputLogin'>
        <span>Code</span>
        <input type='text' onChange={(e) => setCode(e.target.value)} />
      </div>
      <div className='submit' onClick={() => confirmCode()}>
        Submit
      </div>
    </div>
  );
};

export default Login;
