import config from 'config';
import { useState } from 'react';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import { hidePopup } from 'reducer/slices/popupSlice';
import gather from 'tools/gather';

const Add = ({ data = undefined, reload }: any) => {
  const dispatch = useAppDispatch();
  const [username, setUsername] = useState(data?.username);
  const [isActive, setIsActive] = useState(data?.isActive);
  const [password, setPassword] = useState('');

  const submit = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/sub${data ? '/' + data.id : ''}`, true)[data ? 'put' : 'post']({
      username: username,
      password: password,
      isActive: isActive,
    });

    if (result.code === 200) {
      dispatch(hidePopup());
      dispatch(successAlert('Done!'));
      reload();
    } else {
      dispatch(errorAlert(result.message ?? 'Not connected to server'));
    }

    dispatch(hideLoading());
  };

  return (
    <div className='login'>
      <div className='inputLogin'>
        <span>Username</span>
        <input type='text' value={username} onChange={(e) => setUsername(e.target.value)} />
      </div>
      <div className='inputLogin'>
        <span>Password</span>
        <input type='password' onChange={(e) => setPassword(e.target.value)} />
      </div>
      <div className='inputLogin'>
        <span>Active?</span>
        <select defaultValue={isActive} onChange={(e) => setIsActive(e.target.value)}>
          <option value={'true'}>true</option>
          <option value={'false'}>false</option>
        </select>
      </div>
      <div className='submit' onClick={() => submit()}>
        Submit
      </div>
    </div>
  );
};

export default Add;
