import Header from 'components/layouts/Header';
import Menu from 'components/layouts/Menu';
import config from 'config';
import { useEffect, useState } from 'react';
import { FaPen } from 'react-icons/fa';
import { useAppDispatch } from 'reducer';
import { errorAlert } from 'reducer/slices/alertSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import { showPopup } from 'reducer/slices/popupSlice';
import gather from 'tools/gather';
import Add from './Add';

const Sub = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState([]);

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/sub`, true).get();
    if (result.code === 200) {
      setData(result.data);
    } else {
      dispatch(errorAlert(result.message));
    }

    dispatch(hideLoading());
  };

  const list = () => {
    const rows: React.ReactElement[] = [];

    data.forEach((e: any, i: number) => {
      rows.push(
        <tr key={i}>
          <td>{e.id}</td>
          <td>{e.username}</td>
          <td>{e.isActive + ''}</td>
          <td className='setting'>
            <span className='penEdit' onClick={() => dispatch(showPopup(<Add data={e} reload={init} />))}>
              <FaPen />
            </span>
          </td>
        </tr>,
      );
    });

    return rows;
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Menu />
      <div className='container'>
        <Header />
        <div className='topButtons'>
          <span onClick={() => dispatch(showPopup(<Add reload={init} />))}>Add New</span>
        </div>
        <table className='tableData'>
          <tbody>
            <tr>
              <td>ID</td>
              <td>Username</td>
              <td>Active</td>
              <td>Setting</td>
            </tr>
            {list()}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Sub;
