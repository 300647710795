import config from 'config';
import Cookies from 'js-cookie';
import { FaLock } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';

const Menu = () => {
  const location = useLocation();

  const logout = () => {
    Cookies.remove('refreshTokenPanel');
    Cookies.remove('accessTokenPanel');
    Cookies.remove('isSub');
    window.location.reload();
  };

  const list = () => {
    const rows: React.ReactElement[] = [];

    config.menu.forEach((e, i) => {
      rows.push(
        <Link key={i} className={e.url === location.pathname ? 'active' : ''} to={e.url}>
          {e.icon}
          {e.title}
        </Link>,
      );
    });

    return rows;
  };

  return (
    <div className='menu'>
      <div className='menuList'>
        {list()}
        <a onClick={() => logout()}>
          <FaLock />
          Logout
        </a>
      </div>
    </div>
  );
};

export default Menu;
